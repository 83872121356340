import React, { FunctionComponent, useState } from "react";
import {
    RouteComponentProps,
    navigate,
    useParams,
    useLocation,
} from "@reach/router";
import {
    Heading,
    HStack,
    VStack,
    Text,
    Button,
    RadioGroup,
    Stack,
    Radio,
    Box,
    useDisclosure,
} from "@chakra-ui/react";
import { Loading } from "../../components/common/Loading";
import { Searchbar } from "../../components/common/Searchbar";
import { useApp } from "../../../lib/app/App";
import { RellieSelect } from "../../components/common/RellieSelect";
import { SelectedClassData, TopStrategies } from "./TopStrategies";
import { isEmpty } from "lodash";
import { CognitiveArea, FullReportTable, Student } from "./FullReportTable";
import { StrategySelector } from "./StrategySelector";
import { StrategyReportElement } from "../../components/StrategyReportElement";
import { IoIosArrowRoundBack } from "react-icons/io";
import { StrategyFeedbackModal } from "../../components/popup/StrategyFeedbackModal";

export const handleColorPercentage = (
    percentage: number,
    isColorScheme = true,
) => {
    if (percentage < 60) {
        return isColorScheme ? "brand.orange" : "#F56806";
    }
    if (percentage < 80) {
        return isColorScheme ? "brand.yellow" : "#FBBC04";
    }
    // if (percentage >= 80 && percentage < 89) {
    //     return isColorScheme ? "brand.blue" : "#136AFF";
    // }
    return isColorScheme ? "brand.green" : "#1CCF8C";
};

export const handleTextPercentage = (percentage: number) => {
    if (percentage < 60) {
        return "NEEDS DEVELOPMENT";
    }
    if (percentage < 80) {
        return "DEVELOPING";
    }
    return "DEVELOPING WELL";
};

export const TeacherClassReportPage: FunctionComponent<
    RouteComponentProps
> = () => {
    const { api, useData } = useApp();
    const params = useParams();
    const { pathname, hash, search } = useLocation();
    const isStrategySelected = pathname.includes("strategies");

    const classesData = useData("Teacher.Classes");

    const selectedClass = params.administratorTeacherLicenseId || "";

    let selectedClassData = {
        query: {
            Id_Administrator_Teacher_License: -1,
        },
    };
    if (classesData.data && selectedClass) {
        classesData.data.forEach((classData) => {
            if (classData.id == selectedClass) {
                selectedClassData = classData;
                selectedClassData.query = {
                    Id_Administrator_Teacher_License: selectedClassData.id,
                };
            }
        });
    }

    const classFullData = useData("Teacher.Report.Class", {
        ...selectedClassData.query,
    });

    const strategyFeedback = useData("Teacher.Report.Strategy.Feedback", {
        Id_Administrator_Teacher_License: selectedClassData?.id || -1
    });

    // console.log("classesData", classesData.data);
    // console.log("classFullData", classFullData.data);

    let selectedCognitiveAreaId = null;
    if (hash) {
        const m = hash.match(/^#strategy-(\d+)$/);
        if (m) {
            selectedCognitiveAreaId = parseInt(m[1]);
        }
    }

    if (
        !selectedCognitiveAreaId &&
        classFullData.data?.cognitiveAreas &&
        classFullData.data.cognitiveAreas.length
    ) {
        selectedCognitiveAreaId = classFullData.data?.cognitiveAreas[0].Id;
    }

    // console.log("selectedCognitiveAreaId", selectedCognitiveAreaId);

    const currentStrategy = classFullData.data?.cognitiveAreas?.find(
        (cognitiveArea: string) => {
            return cognitiveArea.Id === selectedCognitiveAreaId;
        },
    );

    // console.log("currentStrategy", currentStrategy);
    // console.log("classFullData.data.strategies", classFullData.data.strategies);

    const currentStrategies = classFullData.data?.strategies?.filter(
        (strategy) => strategy.Id_CognitiveArea.includes(selectedCognitiveAreaId),
    );

    if (currentStrategies && strategyFeedback.data) {
        for (const strategy of currentStrategies) {
            strategy.feedback = strategyFeedback.data[`${strategy.Id_Strategy}`] || {}
        }
    }

// console.log('currentStrategies', currentStrategies)    
    // strategyFeedback.data    

    function onBackToOverview() {
        navigate(`/teacher/reports/${selectedClass}`);
    }

    const showStrategiesForCognitiveArea = (cognitiveAreaId: number) => {
        const url = []
        if (!/\/strategies/.test(location.pathname)) {
            url.push(`${location.pathname}/strategies`)
        } else {
            url.push(location.pathname)
        }
        url.push(location.search)
        url.push(`#strategy-${cognitiveAreaId}`)
        navigate(url.join(''))
    };

    const cognitiveAreaLabels = {};
    classFullData.data?.cognitiveAreas?.forEach((cognitiveArea: string) => {
        cognitiveAreaLabels[cognitiveArea.Id] = cognitiveArea.label;
    });
    // console.log("cognitiveAreaLabels", cognitiveAreaLabels);


    const strategyFeedbackModal = useDisclosure();
    const [ dataForStrategyFeedbackModal, setDataForStrategyFeedbackModal ] = useState(null)

    const onProvideStrategyFeedback = async (strategyId, rating) => {
        setDataForStrategyFeedbackModal({
            feedback: '',
            ...strategyFeedback.data[`${strategyId}`] || {},
            strategyId,
            rating,
        })
        strategyFeedbackModal.onOpen()
    }

    const onSubmitStrategyFeedback = async ({ strategyId, rating, feedback }) => {
        await api.Teacher.Report.Strategy.Feedback.Save.mutate({
            Id_Administrator_Teacher_License: selectedClassData.id,
            Id_Strategy: strategyId,
            rating,
            feedback
        })
        strategyFeedback.refetch()
    }

    if (!selectedClass && classesData.data?.length) {
        navigate(`/teacher/reports/${classesData.data[0].id}`);
        return null;
    }

    if (!classesData.data || !classFullData.data?.cognitiveAreas) {
        return <Loading />
    }


    const renderContent = () => {
        if (isStrategySelected) {
            return (
                <>
                    <HStack w={"100%"} justifyContent={"space-between"}>
                        <Heading
                            marginBottom={"22px"}
                            fontSize={"20px"}
                            color={"#032E59"}
                        >
                            Class Strategies
                        </Heading>
                        <Button
                            fontSize={"10px"}
                            color={"#032E59"}
                            borderRadius={"60px"}
                            height={"22px"}
                            leftIcon={<IoIosArrowRoundBack />}
                            onClick={onBackToOverview}
                        >
                            Back to Class Report
                        </Button>
                    </HStack>
                    <StrategySelector
                        isCognitiveAreaActive={(cognitiveAreaId) => {
                            return cognitiveAreaId === selectedCognitiveAreaId;
                        }}
                        onCognitiveAreaClick={showStrategiesForCognitiveArea}
                        cognitiveAreas={classFullData.data.cognitiveAreas}
                        isShowPercentage={true}
                    />
                    <VStack
                        spacing={"13px"}
                        w={"100%"}
                        p={"30px"}
                        maxW={"100%"}
                    >
                        {currentStrategy && (
                            <StrategyReportElement
                                selectedCognitiveAreaId={
                                    selectedCognitiveAreaId
                                }
                                cognitiveId={currentStrategy.id}
                                strategies={currentStrategies}
                                title={currentStrategy.label}
                                colorScheme={handleColorPercentage(currentStrategy.percentage, true)}
                                onProvideStrategyFeedback={onProvideStrategyFeedback}
                            />
                        )}
                    </VStack>
                </>
            );
        }

        if (selectedClass === "" && isEmpty(classFullData.data.strategies)) {
            return <Text>Select a class to see the report.</Text>;
        }

        if (classFullData.data.error) {
            return (
                <Text>There was an error loading the report! Please try again later.</Text>
            )
        }

        return (
            <>                
                {/* <TopStrategies
                    strategies={classFullData.data.strategies
                        .slice(0, 12)
                        .map((strategy) => {
                            strategy.cognitiveAreaLabel =
                                strategy.Id_CognitiveArea.map((id) => cognitiveAreaLabels[id]);
                            return strategy;
                        })}
                    selectedClassData={
                        selectedClassData as unknown as SelectedClassData
                    }
                /> */}

                <Heading size={"md"} color={"#032E59"}>
                    Class Strategies
                </Heading>

                <StrategySelector
                    isCognitiveAreaActive={(cognitiveAreaId) => false}
                    onCognitiveAreaClick={showStrategiesForCognitiveArea}
                    cognitiveAreas={classFullData.data.cognitiveAreas}
                    isShowPercentage={true}
                />

                <FullReportTable
                    selectedClass={selectedClass}
                    cognitiveAreas={
                        classFullData.data
                            .cognitiveAreas as unknown as CognitiveArea[]
                    }
                    students={
                        classFullData.data.students as unknown as Student[]
                    }
                />
            </>
        );
    };

    return (
        <>
            {strategyFeedbackModal.isOpen && (<StrategyFeedbackModal
                isOpen={strategyFeedbackModal.isOpen}
                onClose={strategyFeedbackModal.onClose}
                defaultValues={dataForStrategyFeedbackModal}
                onSubmit={async (data) => {

                    await onSubmitStrategyFeedback(data)

                    strategyFeedbackModal.onClose()
                }}
            />)}
            <VStack alignItems={"start"} spacing={"25px"}>
                <Searchbar />
                <HStack justifyContent={"space-between"} w={"100%"}>
                    <RellieSelect
                        options={classesData.data.map((classData) => ({
                            label: classData.label,
                            value: `${classData.id}`,
                        }))}
                        onChangeHandler={(value) => {
                            navigate(`/teacher/reports/${value}`);
                        }}
                        value={selectedClass}
                        placeholder={"SELECT CLASS"}
                    />
                </HStack>

                {renderContent()}
            </VStack>
        </>
    );
};
